import { BaseApiService } from "@/utils/BaseApiService";
import { UserSessionUtils } from "@/utils/UserSessionUtils";
import Link from "next/link";
import React, { useState } from "react";
import { useRouter } from 'next/router';

const Login = () => {
  const [state, setState] = useState({ emailAddress: '', password: '' });
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const router = useRouter();

  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const { emailAddress, password } = state;

  const login = async (event) => {
    event.preventDefault();
    setErrorMessage(''); // Clear previous error messages
    setSuccessMessage(''); // Clear previous success messages

    if (!emailAddress || !password) {
      setErrorMessage('Please fill in email and password details.');
      return;
    }

    setLoading(true); // Start loader

    let loginRequest = { username: emailAddress, password };

    try {
      const response = await new BaseApiService("/auth/login").postRequestWithJsonResponse(loginRequest);
      console.log("Response " + JSON.stringify(response));
      if (response?.user != null) {
        UserSessionUtils.setFullSessionObject(response);
        UserSessionUtils.setUserDetails(response?.user);
        UserSessionUtils.setUserAuthToken(response?.accessToken);
        UserSessionUtils.setUserRefreshToken(response?.refreshToken);
        UserSessionUtils.setLoggedIn(true);
        setSuccessMessage('Login successful! Redirecting...');
        setTimeout(() => {
          router.push('/student/student-dashboard');
        }, 2000); // Redirect after a short delay to show the success message
      } else {
        setErrorMessage(response.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during login.');
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <>
      <div className="col-lg-6">
        <div className="rbt-contact-form contact-form-style-1 max-width-auto">
          <h3 className="title">Login</h3>
          <form className="max-width-auto" onSubmit={login}>
            <div className="form-group">
              <input
                name="con_name"
                type="text"
                placeholder="Email *"
                value={emailAddress}
                onChange={(e) => updateState({ emailAddress: e.target.value })}
              />
              <span className="focus-border"></span>
            </div>
            <div className="form-group">
              <input
                name="con_email"
                type="password"
                placeholder="Password *"
                value={password}
                onChange={(e) => updateState({ password: e.target.value })}
              />
              <span className="focus-border"></span>
            </div>

            <div className="row mb--30">
              <div className="col-lg-6">
                <div className="rbt-checkbox">
                  <input type="checkbox" id="rememberme" name="rememberme" />
                  <label htmlFor="rememberme">Remember me</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="rbt-lost-password text-end">
                  <Link className="rbt-btn-link" href="#">
                    Lost your password?
                  </Link>
                </div>
              </div>
            </div>

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}

            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            <div className="form-submit-group">
              <button
                type="submit"
                className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100"
                disabled={loading} // Disable button while loading
              >
                <span className="icon-reverse-wrapper">
                  <span className="btn-text">
                    {loading ? 'Logging in...' : 'Log In'}
                  </span>
                  {!loading && (
                    <>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </>
                  )}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
